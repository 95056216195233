#not_found {
  position: relative;
  height: 100vh;
  background: url("../../assets/logo/Logo-sign-mockup-on-dark-wall-with-lights.jpg")
    no-repeat center center/cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

#not_found::after {
  content: "";
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 0;
}

.not_found_wrapper {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  text-align: center;
}

.not_found_wrapper h2 {
  color: #fff;
  font-size: 2rem;
}

.not_found_wrapper p:nth-child(2) {
  font-size: 1.5rem;
}

.not_found_wrapper p {
  color: #fff;
  max-width: 600px;
}

.not_found_wrapper a {
  color: var(--blue-2);
  transition: opacity 300ms ease;
}

.not_found_wrapper a:hover {
  opacity: 0.6;
}
