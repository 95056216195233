.faq_wrapper .top {
  align-self: flex-start;
}

.faq_wrapper .top p {
  text-align: left;
  width: 100%;
  font-size: 2rem;
  margin-bottom: 1.5rem;
  font-weight: 500;
}

.faq_wrapper .bottom {
  background: var(--blue);
  min-height: 35rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.faq_wrapper .bottom_wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;
  margin: 3rem auto;
}

.faq_wrapper .bottom_wrapper p {
  color: #fff;
  width: 100%;
  text-align: left;
}

.faq_wrapper .bottom_wrapper .question {
  font-size: 1.7rem;
  margin-bottom: 1.3rem;
}

@media screen and (max-width: 1000px) {
  .faq_wrapper .bottom_wrapper {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 600px) {
  .faq_wrapper .top p {
    font-size: 1.5rem;
  }

  .faq_wrapper .bottom_wrapper {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .faq_wrapper .bottom_wrapper .question {
    font-size: 1.4rem;
  }
}
