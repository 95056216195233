.clint-case {
    background-color: var(--blue);
    padding-bottom: 5rem;
}



.clint-case .swiper-slide {
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.clint-case .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%!important;
}

.clint-case .swiper-slide .cls-1 {
    border-radius: 50%!important;
    height: 170px;
    width: 170px;
    padding: .5rem!important;
    background: var(--blue)!important;
}


.clint-case .tm-swiper .swiper-button-prev::after {
    display: none;
}
.clint-case .tm-swiper .swiper-button-next::after {
    display: none;
}




.clint-swipe {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2em;
    border-radius: 20px;
    background: #fff;
}



.supes-slide {
    padding: 2rem;
    border-radius: 20px!important;
}


.cls-2 {
    font-size: 20px;
    color: var(--grey-2);
    font-weight: 700;
}

.cls-3 {
    font-size: 16px;
    color: var(--grey-2);
    text-align: left;
}

.cls-1 {
    width: 90%;
    height: 90%;
    border-radius: 50%!important;
}

.swiper-button-prev, .swiper-button-next {
    border: none!important;
}