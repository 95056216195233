
/* showcase  */

.testimonial-showcase-1 {
    background: url('../../assets/images/testy.jpg') no-repeat center center/cover;
    height: 100vh;
    width: 100%;
}



/* showcase end */


/* clients  */


.clients-wrapper {
    background: var(--blue);
}

.clients-case {
    padding: 5rem 0;
}

.clients {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* gap: 1rem; */
}

.clients-say {
    font-size: 2rem;
    color: #fff;
    font-weight: 600;
    text-align: center;
}

@media screen and (max-width: 600px) {
    .clients-say {
    font-size: 1.5rem;
}
}


/* clients bot  */



/* clients bot end */

/* clients end */



/* services  */


.serve-wrapper {
    background: url('../../assets/images/serv.jpg') no-repeat center center/cover;
    /* min-height: 100vh; */
    height: 100%;
    width: 100%;
}

.serve-case {
    background: rgba(0, 0, 0, .6);
    height: 100%;
    /* min-height: 100vh; */
    width: 100%;
}

.serve-show {
    display: flex;
    justify-content: center;
    align-items: center;
    /* min-height: 100vh; */
    min-height: 100%;
    padding: 7rem 0;
}

.serve {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    gap: 2em;
    width: 100%;
}

.serve-left {
    width: 30%;
}

.sl-pic {
    width: 100%;
    height: 400px;
}

.sl-pic img {
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.serve-right {
    width: 65%;
}

.sr-case {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3.5rem;
}

.sr-1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2em;
    width: 21%;
    color: #fff;
}

.sr-1-a {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
}

.sr-1-b {
    width: 65px;
    height: 65px;
}

.sr-1-b img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.sr-1-c {
    font-size: 16px;
}

.sr-1-d .btn-main {
    background: transparent;
    border: #fff 2px solid;
    transition: all .3s ease;
    cursor: pointer;
    display: inline-block;
    width: fit-content;
    min-width: 10rem;
    text-align: center;
    font-size: 16px;
}

.sr-1-d .btn-main:hover {
    background-color: rgba(0, 0, 0, 0.7);
    transform: scale(0.94);
    box-shadow: 0 1rem 1.6rem rgba(#03b5e6, 0.15);
    transition: all .3s ease;
}



@media screen and (max-width: 1000px) {

    .serve-show {
    padding: 30rem 0;
}
    
    .serve {
    flex-direction: column;
    /* justify-content: center; */
    gap: 2em;
    position: relative;
}

.serve-left {
    width: 50%;
    position: absolute;
    left: 0;
    top: -28rem;
}
.serve-right {
    width: 100%;
    top: -3rem;
    position: absolute;
}

.sr-1 {
    width: 25%;
}

.sr-case {
    gap: 3rem;
}

}


@media screen and (max-width: 600px) {
    
    .sr-case {
    flex-direction: column;
    gap: 4rem;
}


    .serve-show {
    padding: 55rem 0;
    /* height: 100%; */
}

.serve-left {
    width: 60%;
    position: absolute;
    left: 0;
    top: -57rem;
}


.serve-right {
    width: 100%;
    top: -35rem;
    position: absolute;
}

.sr-1 {
    width: 80%;
    margin: auto;
    box-shadow: var(--blue) 0px 10px 20px, var(--blue) 0px 6px 6px;
    padding: 3rem 1rem;
    height: 400px;
}

.sr-1-c {
    text-align: center;
}

}


/* services end */


/* privacy  */


.privacy-wrapper #privacy {
    min-height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.privacy-wrapper #privacy .container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.privacy-wrapper #privacy .container article h1 {
    color: var(--grey-2);
    font-size: 2.5rem;
    text-align: center;
}

.privacy-wrapper #privacy-bgVid {
    position: absolute;
    left: 0;
    width: 100vw;
    height: 40vh;
    object-fit: cover;
    z-index: -1;
}


@media screen and (max-width: 600px) {
    .privacy-wrapper #privacy {
        min-height: 40vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    .privacy-wrapper #privacy .container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .privacy-wrapper #privacy .container article h1 {
        font-size: 2rem;
    }

    .privacy-wrapper #privacy-bgVid {
        width: 100vw;
        height: 40vh;
        object-fit: cover;

    }
}

/* privacy end */