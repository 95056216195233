#service_showcase {
  position: relative;
  height: 100vh;
  background: url("../../assets/images/reception.jpg") no-repeat center
    center/cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

#service_showcase::after {
  content: "";
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 0;
}

#service_showcase .showcase_wrapper {
  position: relative;
  z-index: 1;
  margin-top: 5rem;
}

#service_showcase .showcase_wrapper .top {
  background-color: #fff;
  padding: 1.5rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  margin-bottom: 2rem;
}

#service_showcase .showcase_wrapper .top h2 {
  margin-bottom: 0;
  text-transform: uppercase;
}

#service_showcase .showcase_wrapper .bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.7rem;
}

#service_showcase .showcase_wrapper .bottom img {
  width: 40px;
}

/* ======= SERVICE_WHAT SECTION ======= */
.service_what_wrapper {
  margin: 4rem 0;
}

.service_what_wrapper .top p:first-child {
  margin-bottom: 1rem;
  font-weight: 500;
  max-width: 400px;
  line-height: 1.2;
  text-transform: capitalize;
}

.service_what_wrapper .top p:last-child {
  width: 60%;
  padding: 0.5rem 0;
}

.service_what_wrapper .bottom {
  position: relative;
  background: url("../../assets/images/black_business.jpg") no-repeat center
    center/cover;
  min-height: 40rem;
  padding: 4rem 0;
}

.service_what_wrapper .bottom::after {
  content: "";
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 0;
}

.service_what_wrapper .bottom h2 {
  margin-bottom: 4rem;
  text-transform: capitalize;
  position: relative;
  z-index: 1;
}

.service_what_wrapper .bottom .box_content {
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  position: relative;
  z-index: 1;
}

.service_what_wrapper .box_content .s_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.service_what_wrapper .box_content .s_box img {
  width: 70px;
  margin-bottom: 0.5rem;
}

.service_what_wrapper .box_content .s_box p {
  color: #fff;
  width: 100%;
}

.service_what_wrapper .box_content .s_box p:nth-child(2) {
  width: 50%;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 1rem;
  line-height: 1.3;
  text-transform: capitalize;
}

.service_what_wrapper .box_content .s_box a {
  background-color: transparent;
  border: 1px solid #fff;
  text-transform: capitalize;
  transition: 300ms ease;
}

.service_what_wrapper .box_content .s_box a:hover {
  background-color: var(--black-2);
  /* border: 1px solid #fff; */
}

/* =======SERVICE_DEALINGS SECTION ======= */
#service_dealings {
  margin-bottom: 4rem;
}

.service_dealings_wrapper .top p {
  line-height: 1.2;
  width: 100%;
}

.service_dealings_wrapper .dealings_head {
  font-size: 2rem;
  text-transform: capitalize;
  color: var(--blue-2);
  font-weight: 500;
}

/* ======= service_awards section ======= */
.service_awards_wrapper {
  max-width: 700px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4rem;
}

.service_awards_wrapper .type {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
}

.service_awards_wrapper img {
  width: 50px;
}

.service_awards_wrapper h3 {
  font-weight: 600;
  text-align: center;
  max-width: 70px;
  color: var(--blue);
}

/* ======= MEDIA QUERIES ======= */

@media screen and (max-width: 600px) {
  .service_what_wrapper .top p:first-child {
    font-size: 1.7rem;
    width: 70%;
  }

  .service_what_wrapper .top p:last-child {
    width: 100%;
  }

  .service_what_wrapper .bottom h2 {
    margin: 3rem;
    font-size: 1.7rem;
  }

  .service_what_wrapper .bottom .box_content {
    flex-direction: column;
    gap: 5rem;
  }

  .service_what_wrapper .box_content .s_box p:nth-child(2) {
    width: 60%;
  }

  .service_what_wrapper .box_content .s_box p:nth-child(3) {
    margin-bottom: 1.5rem;
  }

  .service_dealings_wrapper .top p {
    font-size: 1.7rem;
  }

  .service_awards_wrapper .type {
    gap: 1rem;
  }

  .service_awards_wrapper h3 {
    font-size: 16px;
  }

  .service_awards_wrapper img {
    width: 30px;
  }
}

@media screen and (max-width: 500px) {
  #service_showcase .showcase_wrapper .top {
    padding: 1rem;
  }

  #service_showcase .showcase_wrapper .top h2 {
    font-size: 2rem;
  }
}
