
/* showcase  */

.mission-showcase-1 {
    background: url('../../assets/images/mission.jpg') no-repeat center center/cover;
    height: 100vh;
    width: 100%;
}

.mission-showcase-2 {
    background: rgba(0, 0, 0, .6);
    height: 100%;
    width: 100%;
}

.mission-showcase-txt {
    color: #fff;
    text-align: center;
}


/* showcase end */


/* strategy  */

#strategy-wrapper {
    background: var(--blue);
}

.strategy-case {
    padding: 5rem 0;
}

.strategy {
    display: flex;
    justify-content: center;
    gap: 2rem;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
}

.strategy-1 {
    /* width: 23%; */
    width: 270px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: #fff 2px solid;
    border-radius: 10px;
    padding: 2rem 1rem;
    color: #fff;
}

.stra-1 img {
    width: 85%;
    object-fit: cover;
}

.stra-2 {
    font-size: 20px;
    font-weight: 700;
    margin: 1.5rem 0;
    text-align: center;
}

.stra-3 {
    font-size: 16px;
    text-align: left;
}

@media screen and (max-width: 600px) {

    .strategy {
    flex-direction: column;
}

.strategy-1 {
    width: 100%;
}
    
}

/* strategy end */

/* together  */

.toge-wrapper {
    background: url('../../assets/images/viss2.jpg') no-repeat center center/cover;
}

.toge-case {
    background: rgba(0, 0, 0, .6);
    height: 100%;
    width: 100%;
}

.toge-show {
    padding: 5rem 0;
}

.toge {
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.toge-1 {
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 4rem;
}

.toge-2 {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}

.toge2-left {
    width: 45%;
}

.toge2-lt {
    font-size: 20px;
    text-align: left;
    line-height: 1.7;
    margin-bottom: 3rem;
}

.toge2-lb {
    font-size: 20px;
    text-align: left;
    line-height: 1.7;
}


.toge2-right {
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 2rem;
}

.toge2-rt {
    font-size: 20px;
}

.toge2-rb {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1rem;
}

.toge2-rb-1 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5em;
}

/* .trb-log {} */

.trb-txt {
    background: var(--blue);
    padding: .8rem 2rem;
}



@media screen and (max-width: 900px) {
    

.toge-2 {
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.toge2-left {
    width: 100%;
}

.toge2-right {
    width: 100%;
    margin-top: 3rem;
}

}


@media screen and (max-width: 600px) {
    .trb-txt {
    background: var(--blue);
    padding: .8rem 1rem;
}

.toge2-lt {
    font-size: 16px;
}

.toge2-lb {
    font-size: 16px;
}
}

/* together end */


/* values  */

#values-wrapper {
    background: #fff;
    width: 100%;
}

.values-case {
    padding: 5rem 0;
}

.values {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

.values-1 {
    font-size: 2rem;
    color: var(--blue);
    font-weight: 700;
    text-align: center;
}

.values-2 {
    font-size: 16px;
    color: var(--grey-2);
    line-height: 1.7;
    text-align: left;
}

.values-3 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    width: 100%;
    margin-top: 3rem;
}

.val3 {
    width: 31%;
    min-height: 430px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 2rem;
    background: var(--blue);
    padding: 3rem 2rem;
    border-radius: 10px;
}

.val3-a {
    font-size: 20px;
    color: #fff;
    font-weight: 700;
}
.val3-b {
    font-size: 16px;
    color: #fff;
    line-height: 1.7;
}


@media screen and (max-width: 950px) {
    .values-3 {
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    margin-top: 3rem;
}

.val3 {
    width: 80%;
    margin: auto;
    height: 100%;
    gap: 2rem;
    padding: 3rem 2rem;
}
}

/* values end */



/* mission  */

#mission-page {
    background: url('../../assets/images/viss2.jpg') no-repeat center center/cover;
}

.mission-case-page {
    background: rgba(0, 0, 0, .6);
    height: 100%;
    width: 100%;
}

.mission-show-page {
    padding: 7rem 0;
}

.mission-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4rem;
}

.mission-page-top {
    font-size: 2rem;
  color: var(--blue);
  background: #fff;
  padding: 1rem 2rem!important;
  border-radius: 10px;
  font-weight: 800;
  text-align: center;
  font-weight: 700;
}

.mission-page-bot {
    font-size: 16px;
    color: #fff;
    line-height: 1.7;
    text-align: left;
}

/* mission end */