#mineral_showcase {
  position: relative;
  min-height: 100vh;
  background: url("../../assets/images/data.jpg") no-repeat center center/cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

#mineral_showcase::after {
  content: "";
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.7);
}

.mineral_showcase_wrapper {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  margin-top: 7rem;
}

.mineral_showcase_wrapper .left {
  flex-basis: 100%;
}
.mineral_showcase_wrapper p {
  color: #fff;
  text-align: left;
  width: 100%;
}

.mineral_showcase_wrapper .head {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.mineral_showcase_wrapper .text {
  padding-left: 1rem;
}

.mineral_showcase_wrapper .text p:first-child {
  font-size: 3rem;
  line-height: 1.2;
  font-weight: 600;
  margin: 0;
  max-width: 600px;
}

.mineral_showcase_wrapper .text p:last-child {
  padding-top: 2rem;
  font-size: 1.5rem;
}

.mineral_showcase_wrapper .right {
  flex-basis: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 3rem;
}

.mineral_showcase_wrapper .right .number {
  font-size: 2rem;
  font-weight: 600;
  width: fit-content;
  border-radius: 10rem 0 10rem 0;
  background-color: var(--blue-2);
  color: #fff;
  padding: 1.8rem 2.7rem;
  transition: 300ms ease;
}

.mineral_showcase_wrapper .right .number:hover {
  background-color: var(--black-2);
  color: #fff;
}

/* =======LEARN SECTION ======= */

.mineral_learn_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  margin: 5rem auto;
}

.mineral_learn_wrapper .left,
.mineral_learn_wrapper .right {
  max-width: 50%;
}

.mineral_learn_wrapper .left p {
  width: 90%;
}

.mineral_learn_wrapper .right .utube_frame {
  max-width: 100%;
  border-radius: 1.5rem;
}

/* MINERAL_BELT SECTION */
#mineral_belt {
  height: auto;
}

.belt_wrapper {
  min-height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.belt_wrapper .belt_box {
  min-height: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: var(--blue-2);
  transition: 400ms ease;
}

/* .belt_wrapper .belt_box:nth-child(3) {
  transition-delay: 400ms !important;
} */

.belt_wrapper .belt_box:hover {
  background-color: #fff;
}

.belt_wrapper .belt_box .circle {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5rem;
  transition: 400ms ease;
}

.belt_wrapper .belt_box .circle img {
  width: 60px;
  transition: 400ms ease;
}

.belt_wrapper .belt_box p:nth-child(2) {
  width: 100%;
  color: #fff;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 0.5rem;
  transition: 400ms ease;
}

.belt_wrapper .belt_box p {
  transition: 400ms ease;
}

.belt_wrapper .belt_box p:nth-child(3) {
  width: 82%;
}

.belt_wrapper .belt_box:hover .circle {
  background-color: var(--blue-2);
}

.belt_wrapper .belt_box:hover .circle img {
  filter: grayscale(100%);
}

.belt_wrapper .belt_box:hover p {
  color: var(--blue-2);
}

.belt_wrapper .belt_box:hover p:nth-child(2) {
  color: var(--grey-2);
}

.belt_wrapper .belt_box:nth-child(2) {
  background-color: var(--light);
}

.belt_wrapper .belt_box:nth-child(2) .circle {
  background-color: var(--blue-2);
}

.belt_wrapper .belt_box:nth-child(2) p:nth-child(2) {
  color: var(--grey-2);
}

/* second box hover effects */

.belt_wrapper .belt_box:nth-child(2):hover .circle {
  background-color: var(--blue-2);
}

.belt_wrapper .belt_box:nth-child(2):hover .circle img {
  filter: invert(50%);
}

.belt_wrapper .belt_box:nth-child(2):hover p {
  color: var(--blue-2);
}

.belt_wrapper .belt_box:nth-child(2):hover p:nth-child(2) {
  color: var(--blue-2);
}

/* ======== MINERAL_RISK_CONTROL SECTION ======= */

.risk_control_wrapper {
  margin: 5rem auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.risk_control_wrapper .left,
.risk_control_wrapper .right {
  width: 100%;
}

.risk_control_wrapper .left {
  display: flex;
  align-items: center;
  justify-content: center;
}

.risk_control_wrapper .left img {
  max-width: 500px;
  border-radius: 50px;
}

.risk_control_wrapper .right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.risk_control_wrapper .circle {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background-color: var(--light);
  display: flex;
  align-items: center;
  justify-content: center;
}

.risk_control_wrapper .circle img {
  width: 60px;
}

.risk_control_wrapper .right p:nth-child(2) {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0.5rem;
  width: 100%;
}

.risk_control_wrapper .right p:nth-child(3) {
  width: 100%;
}

/* ======= MINERAL_DISCLAIMER SECTION ======= */
.mineral_disclaimer_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem 0;
}

.mineral_disclaimer_wrapper p:first-child {
  margin-bottom: 1.5rem;
  width: 100%;
}

.mineral_disclaimer_wrapper .disclaimer {
  width: 100%;
  text-align: left;
}

@media screen and (max-width: 900px) {
  .mineral_showcase_wrapper {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .mineral_showcase_wrapper .right {
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 0rem;
  }

  .mineral_learn_wrapper {
    flex-direction: column;
  }

  .mineral_learn_wrapper {
    margin: 4rem auto;
  }

  .mineral_learn_wrapper .left,
  .mineral_learn_wrapper .right {
    max-width: 100%;
  }

  .mineral_learn_wrapper .left p {
    width: 100%;
  }

  .mineral_learn_wrapper .left p:nth-child(1) {
    font-size: 2rem;
    width: 90%;
  }

  .belt_wrapper {
    flex-direction: column;
  }

  .risk_control_wrapper {
    flex-direction: column;
  }
}

@media screen and (max-width: 600px) {
  .mineral_learn_wrapper .left p {
    width: 100%;
  }

  .mineral_showcase_wrapper .text p:first-child {
    font-size: 2rem;
  }

  .mineral_showcase_wrapper .text p:last-child {
    padding-top: 1.7rem;
  }

  .mineral_showcase_wrapper .right .number {
    font-size: 1.3rem;
    padding: 1.5rem 2rem;
  }

  .mineral_showcase_wrapper .right {
    align-items: center;
    justify-content: center;
  }

  .mineral_learn_wrapper .left p:nth-child(1) {
    width: 100%;
    font-size: 1.7rem;
  }

  .risk_control_wrapper .left img {
    border-radius: 40px;
  }

  .mineral_disclaimer_wrapper p:first-child {
    font-size: 2rem;
  }
}
