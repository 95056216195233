#fraud_showcase {
  position: relative;
  min-height: 100vh;
  background: url("../../assets/images/mining_ground.jpg") no-repeat center
    center/cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

#fraud_showcase::after {
  content: "";
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.8);
}

.fraud_showcase_wrapper {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 2rem;
  margin-top: 7rem;
  margin-bottom: 5rem;
}

.fraud_showcase_wrapper .left {
  flex-basis: 100%;
}
.fraud_showcase_wrapper p {
  color: #fff;
  text-align: left;
  width: 100%;
}

.fraud_showcase_wrapper .head {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.fraud_showcase_wrapper .text {
  padding-left: 1rem;
}

.fraud_showcase_wrapper .text p:first-child {
  font-size: 3rem;
  line-height: 1.2;
  font-weight: 600;
  margin: 0;
  max-width: 600px;
}

.fraud_showcase_wrapper .text p:last-child {
  padding-top: 2rem;
  font-size: 1.5rem;
}

.fraud_showcase_wrapper .right {
  flex-basis: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.fraud_showcase_wrapper .right img {
  border-radius: 60px;
}

/* ======= PROCESS SECTION ======= */
.fraud_process_wrapper {
  margin: 5rem auto;
}

.fraud_process_wrapper .top {
  margin-bottom: 7rem;
}

.fraud_process_wrapper .top p {
  width: 80%;
  color: var(--grey-2);
}

.fraud_process_wrapper .bottom {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-area: 2rem;
}

.fraud_process_wrapper .process_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 0.2rem;
  transition: 200ms ease;
}

.fraud_process_wrapper .process_box:hover {
  box-shadow: 0rem 0.5rem 1rem rgba(3, 181, 230, 0.1);
}

.fraud_process_wrapper .process_box p {
  color: var(--grey-2);
}

.fraud_process_wrapper .process_box .circle {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: var(--blue);
  text-align: center;
  color: #fff;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.fraud_process_wrapper .process_box p:nth-child(2) {
  font-weight: 600;
  margin-bottom: 1rem;
  color: var(--blue);
}

.fraud_process_wrapper .process_box p:nth-child(3) {
  width: 90%;
}

/* ======= PROCESS_EQUIPED sECTION ======= */
#fraud_equiped {
  position: relative;
  background: url("../../assets/logo/Logo-sign-mockup-on-dark-wall-with-lights.jpg")
    no-repeat center center/cover;
  min-height: 40rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
}

#fraud_equiped::after {
  content: "";
  position: absolute;
  inset: 0;
  z-index: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

.equiped_wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  z-index: 1;
}

.equiped_wrapper p {
  color: #fff;
}

.equiped_wrapper .top p {
  width: 90%;
}

.equiped_wrapper .bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

/* ======= VIEW SECTION ======= */
#fraud_view {
  min-height: 30rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 90%;
  margin: auto;
}

#fraud_view .view_wrapper {
  max-width: 82%;
  margin: 5rem auto;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding-right: 1.2rem;
}

#fraud_view .swipe_container {
  width: 100%;
  overflow: unset;
  position: unset;
}

#fraud_view .swiper-button-prev {
  left: 0;
  top: 50%;
  z-index: 2;
  border: 0;
}

#fraud_view .swiper-button-next {
  right: 0;
  top: 50%;
  z-index: 2;
  border: 0;
}

#fraud_view .swiper-button-next::after {
  display: none;
}

#fraud_view .swiper-button-prev::after {
  display: none;
}

#fraud_view .view_wrapper .swiper-slide {
  border-radius: 20px;
  overflow: hidden;
}

#fraud_view .view_wrapper .swiper-slide img {
  height: 350px;
  border-radius: 20px;
  object-fit: cover;
  object-position: center center;
  filter: grayscale(100%);
  transition: 400ms ease;
}

#fraud_view .view_wrapper .swiper-slide img:hover,
#fraud_view .view_wrapper .swiper-slide img:focus {
  filter: grayscale(0);
  transform: scale(1.2);
}

/* ======= MEDIA QUERY ======= */

@media screen and (max-width: 1000px) {
  .fraud_showcase_wrapper .text p:first-child {
    font-size: 2.5rem;
    line-height: 1.2;
    font-weight: 600;
    margin: 0;
    max-width: 600px;
  }
}

@media screen and (max-width: 820px) {
  .fraud_process_wrapper .top p {
    width: 100%;
  }
  .fraud_process_wrapper .bottom {
    grid-template-columns: 1fr 1fr;
  }

  .fraud_process_wrapper p {
    width: 100%;
  }

  #fraud_view .view_wrapper {
    padding-right: 1rem;
  }
}

@media screen and (max-width: 800px) {
  .fraud_showcase_wrapper {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 700px) {
  .equiped_wrapper .bottom p {
    width: 100%;
  }

  .equiped_wrapper p {
    width: 100%;
  }

  .equiped_wrapper .top p {
    width: 100%;
  }

  #fraud_view .view_wrapper {
    max-width: 100%;
  }

  #fraud_view .view_wrapper .swiper-slide img {
    height: 300px;
  }
}

@media screen and (max-width: 600px) {
  .fraud_process_wrapper .top {
    margin-bottom: 4rem;
  }

  .fraud_process_wrapper .process_box p:nth-child(2) {
    margin-bottom: 0.5rem;
    width: 45%;
  }

  .fraud_process_wrapper .bottom {
    grid-template-columns: 1fr;
  }

  .fraud_showcase_wrapper .text p:first-child {
    font-size: 2.2rem;
  }

  .fraud_showcase_wrapper .text p:last-child {
    font-size: 16px;
  }
}
