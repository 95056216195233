
/* showcase  */

.about-showcase-1 {
    background: url('../../assets/images/abtshow.jpg') no-repeat center center/cover;
    height: 100vh;
    width: 100%;
}

.about-showcase-2 {
    background: rgba(0, 0, 0, .6);
    height: 100vh;
    width: 100%;
}

.about-showcase-txt {
    color: #fff;
    text-align: center;
}


/* showcase end */


/* who we are  */


.whowe-case {
    background: #fff;
}

.whowe-show {
    margin: 5rem 0;
}

.who-we {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    width: 100%;
    position: relative;
}

.whowe-left {
    width: 20%;
}




.who-left-pic img {
    object-fit: cover;
}

.whowe-right {
    width: 70%;
    margin: auto;
}

.who-right-txt-1 {
    margin-bottom: 1.5rem;
    color: var(--blue);
    font-size: 2rem;
    font-weight: 700;
}

.who-right-txt-2 {
    font-size: 16px;
    line-height: 1.8;
    color: var(--grey-2);
}


@media screen and (max-width: 950px) {
    
    .who-we {
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.whowe-left {
    width: 50%;
    margin: auto;
}

.whowe-right {
    width: 90%;
    margin: auto;
}

.who-left-pic img {
    transform: rotate(90deg);
    /* width: 300px; */
}

.who-right-txt-1 {
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
    text-align: center;
    margin-top: 2rem;
}

}


/* who we are end */



/* mission & vison  */


#mv-wrapper {
    background: url('../../assets/images/miss.jpg') no-repeat center center/cover;
}

.mv-case {
     background: rgba(0, 0, 0, .7);
    height: 100%;
    width: 100%;
}

.mv-show {
    padding: 8rem 0;
}

.mv-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.mv-top-left {
    width: 45%;
}

.mv-tl-case {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 450px;
}

.mv-tl-box {
    background: var(--blue);
    width: 2rem;
    height: 80%;
}

.mv-tl-case img {
    width: 90%;
    height: 100%;
    object-fit: cover;
}

.mv-top-right {
    width: 45%;
    color: #fff;
}

.mv-tr-1 {
    font-size: 2rem;
    color: #fff;
    font-weight: 700;
    margin-bottom: 2rem;
}

.mv-tr-2 {
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    line-height: 1.7;
}
.mv-tr-3 {
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    line-height: 1.7;
    margin-top: 1.3rem;
}


@media screen and (max-width: 950px) {
    
    .mv-top {
    flex-direction: column;
    justify-content: center;
}

.mv-top-right {
    width: 100%;
    margin-top: 4rem;
}

.mv-tr-1 {
    text-align: center;
}
.mv-tl-case {
    height: 350px;
}


.mv-top-left {
    width: 100%;
}


}


/* vission bot  */


.mv-bot {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 8rem;
}

.mv-bot-left {
    width: 45%;
}

.mv-tl-case {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 450px;
}

.mv-tl-box {
    background: var(--blue);
    width: 2rem;
    height: 80%;
}

.mv-tl-case img {
    width: 90%;
    height: 100%;
    object-fit: cover;
}

.mv-bot-right {
    width: 45%;
    color: #fff;
}



@media screen and (max-width: 950px) {
    
    .mv-bot {
    flex-direction: column-reverse;
    justify-content: center;
}

.mv-bot-right {
    width: 100%;
    margin-top: 4rem;
}

.mv-tr-1 {
    text-align: center;
}
.mv-tl-case {
    height: 350px;
}


.mv-bot-left {
    width: 100%;
    margin-top: 5rem;
}


}



/* vission bot end */


/* mission & vison end */



/* why us  */


#whyus-wrapper {
    background: #fff;
    width: 100%;
}

.whyus-show {
    padding: 7rem 0;
}

.why-us {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.why-us-1 {
    font-size: 2rem;
    color: var(--blue);
    font-weight: 700;
    margin-bottom: 1.4rem;
    text-align: center;
}

.why-us-2 {
    font-size: 16px;
    color: var(--grey-2);
    font-weight: 600;
    margin-bottom: 2rem;
    text-align: center;
    line-height: 1.7;
}

.choose {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.choose-1 {
    background: var(--blue);
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    padding: 2rem 1rem;
    color: #fff;
}

.cho1 {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
}
.cho2 {
    margin: 2rem 0;
}

.cho2 img {
    object-fit: cover;
    width: 80%;
}

.cho3 {
    font-size: 16px;
    color: #fff;
    text-align: left;
}

@media screen and (max-width: 970px) {
    .choose {
    flex-direction: column;
}

.choose-1 {
    width: 70%;
    margin: auto;
    margin: 2rem 0;
}
.whyus-show {
    padding: 5rem 0;
}
}

@media screen and (max-width: 600px) {
    .choose {
    flex-direction: column;
}

.choose-1 {
    width: 90%;
    margin: auto;
    margin: 2rem 0;
}

.whyus-show {
    padding: 3rem 0;
}
}



/* why us end */