#add_choices_wrapper {
  min-height: 100vh;
  display: flex;
  align-items: center;
  padding: 3rem;
}

#add_choices_wrapper .add_main {
  margin: 5rem auto;
  display: flex;
  align-items: centr;
  justify-content: center;
  flex-direction: column;
  gap: 0;
}

#add_choices_wrapper p {
  width: 100%;
  text-align: left;
  font-size: 16px;
}

#add_choices_wrapper p:first-child {
  font-size: 1.5rem;
}

#add_choices_wrapper p a {
  color: var(--blue);
}

#add_choices_wrapper p a:hover {
  opacity: 0.85;
}

#add_choices_wrapper .p_head {
  font-weight: 600;
  margin-bottom: 0.5rem;
  font-size: 16px;
}

@media screen and (max-width: 600px) {
  #add_choices_wrapper {
    padding: 3rem 0;
  }
}
