footer {
  min-height: 70vh;
  background-color: var(--blue);
  position: relative;
}

footer .container {
  padding: 3rem 0;
}

footer .container .top-footer-wrapper {
  display: flex;
  gap: 5%;
  color: var(--white-color);
  margin-bottom: 4rem;
  margin-top: 2rem;
  /* border: solid red 2px; */
}

footer .container .top-footer-wrapper .comp-profile {
  width: 30%;
}

footer .container .top-footer-wrapper .comp-profile .header h2 {
  text-transform: uppercase;
  color: var(--white-color);
}

footer .container .top-footer-wrapper .comp-profile .profile-summary {
  margin-top: 1rem;
}

footer .container .top-footer-wrapper .comp-profile .contacts {
  margin-top: 1rem;
}

footer .container .top-footer-wrapper .comp-profile .contacts ul li {
  margin: 1rem 0;
}

footer .container .top-footer-wrapper .permalinks {
  width: 30%;
}

footer .container .top-footer-wrapper .permalinks .header h2 {
  text-transform: capitalize;
  color: var(--white-color);
}

footer .container .top-footer-wrapper .permalinks .menu {
  display: flex;
  flex-direction: column;
}

footer .container .top-footer-wrapper .permalinks .menu .service_menu_item {
  text-transform: capitalize;
  color: var(--white-color);
  margin: 0.5rem 0;
}

footer .container .top-footer-wrapper .permalinks .menu .service_menu_item:hover {
  color: var(--light);
}

footer .container .top-footer-wrapper .search-col {
  width: 30%;
  display: flex;
  flex-direction: column;
}

footer .container .top-footer-wrapper .search-col .search-bar form input[type="text"] {
  padding: 10px;
  font-size: 17px;
  border: 1px solid var(--blue-2);
  float: left;
  width: 80%;
  background: #f1f1f1;
}

footer .container .top-footer-wrapper .search-col .search-bar form .searchButton {
  float: left;
  width: 12%;
  padding: 4.5px;
  background: var(--blue-2);
  color: var(--white-color);
  font-size: 17px;
  border: 1px solid var(--blue-2);
  border-left: none;
  cursor: pointer;
  text-align: center;
}

footer .container .top-footer-wrapper .search-col .search-bar form .searchButton .searchIcon {
  width: 2rem;
  height: 1.5rem;
  /* border: solid red 2px; */
}

footer .container .top-footer-wrapper .search-col .search-bar form button::after {
  content: "";
  clear: both;
  display: table;
}

footer .container .top-footer-wrapper .search-col .text-summary {
  margin-top: 1rem;
}

footer .container .footer-menu {
  border-top: solid white 2px;
  display: flex;
  justify-content: space-between;
  place-items: center;
  text-transform: capitalize;
  font-size: 0.9rem;
  padding: 1rem 0;
}

footer .container .footer-menu .copyright {
  width: 25%;
  justify-content: start;
}

footer .container .footer-menu .copyright .copy-name {
  color: var(--white-color);
}

footer .container .footer-menu .copyright .copy-name a {
  color: var(--white-color);
}

footer .container .footer-menu .copyright .top ul {
  display: flex;
  gap: 5%;
}

footer .container .footer-menu .copyright .top ul li a {
  color: var(--white-color);
}

footer .container .footer-menu .copyright .bottom li a {
  color: var(--white-color);
}

footer .container .footer-menu .menu-items {
  width: 55%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 5%;
  place-items: center;
  /* border: solid red 2px; */
}

footer .container .footer-menu .menu-items .menu_item {
  color: var(--white-color);
  /* border: solid red 2px; */
}

/*============= MEDIA QUERIES (MEDIUM DEVICES) ================*/

@media screen and(max-width:1024px) {}

/*============= MEDIA QUERIES (SMALLER DEVICES) ================*/

@media screen and (max-width: 600px) {
  footer {
    min-height: 70vh;
    padding-bottom: 6rem;
  }

  footer .container {
    padding: 3rem 0;
  }

  footer .container .top-footer-wrapper {
    flex-direction: column;
    margin-bottom: 2rem;
    /* border: solid red 2px; */
  }

  footer .container .top-footer-wrapper .comp-profile {
    width: 100%;
    margin-bottom: 2rem;
  }

  footer .container .top-footer-wrapper .permalinks {
    width: 100%;
    margin-bottom: 2rem;
  }

  footer .container .top-footer-wrapper .search-col {
    width: 100%;
    /* border: solid red 2px; */
  }

  footer .container .top-footer-wrapper .search-col .text-summary {
    margin: 1rem 0;
  }

  footer .container .footer-menu {
    margin-bottom: 4rem;
  }

  footer .container .footer-menu .copyright {
    width: 100%;
  }

  footer .container .footer-menu .menu-items {
    display: none;
  }
}