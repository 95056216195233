#header-section {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

#header-section .showcase {
    display: flex;
    justify-content: center;
    align-items: center;
}

#header-section .showcase .container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5%;
}

#header-section .showcase .container .left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 50%;
}

#header-section .showcase .container .left h1 {
    font-size: 4rem;
    color: var(--blue-2);
}

#header-section .showcase .container .left span {
    color: var(--white-color);
}

#header-section .showcase .container .left p {
    color: var(--white-color);
    margin-top: 2rem;
    width: 100%;
    text-align: left;
}

#header-section .showcase .container .right {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#header-section .showcase .container .right img {
    width: 20rem;
}

#hero-bgVid {
    position: absolute;
    left: 0;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    z-index: -1;
}


#who-are-we {
    background-image: url('./../../assets/images/MaskGroup1.png');
    background-position: left;
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#who-are-we .container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#who-are-we .container .left {
    width: 50%;
}

#who-are-we .container .right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: 50%;
    text-align: right;
}

#who-are-we .container .right h1 {
    font-size: 4rem;
    color: var(--grey-2);
}

#who-are-we .container .right span {
    color: var(--blue-2);
}

#who-are-we .container .right p {
    margin: 2rem 0;
    color: var(--grey-2);
    text-align: right;
    width: 80%;
}

#who-are-we .container .right a:hover {
    background: lighten(8%);
    transform: scale(0.94);
    box-shadow: 0 1rem 1.6rem rgba(#03b5e6, 0.15);
    transition: .2s all ease-in-out;
}



#mission {
    background-image: url('./../../assets/images/Group-8.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#mission .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#mission .container article {
    width: 50%;
}

#mission .container article h1 {
    font-size: 3rem;
    color: var(--white-color);
    text-align: center;
    margin-bottom: 2rem;
}

#mission .container article p {
    color: var(--blue-2);
    text-align: center;
    width: 100%;
    line-height: 1.8;
}


#services {
    min-height: 100vh;
    background-color: var(--blue);
    background-image: url('./../../assets/images/09.png');
    background-position: right -50rem bottom -20rem;
    background-repeat: no-repeat;
    background-size: 100%;
}

#services .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#services .container .top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
}

#services .container .top h1 {
    font-size: 3rem;
    color: var(--white-color);
}

#services .container .top p {
    color: var(--white-color);
    width: 60%;
    text-align: center;
    margin-top: 2rem;
}

#services .container .bottom {
    margin-top: 4rem;
    position: relative;
}

#services .container .bottom .service-cards {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#services .container .bottom .service-cards .service-card {

    color: var(--white-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 23.5%;
    min-height: 25rem;
    gap: 2%;
}

#services .container .bottom .service-cards .service-card h3 {
    text-transform: capitalize;
}

#services .container .bottom .service-cards .service-card img {
    width: 4rem;
    margin-top: 2rem;
}

#services .container .bottom .service-cards .service-card p {
    text-align: center;
    margin: 2rem 0;
    width: 100%;
}

#services .container .bottom .service-cards .service-card a {
    color: var(--white-color);
    font-weight: 600;
    text-transform: capitalize;
    transform: all .3s ease-in;
}

#services .container .bottom .service-cards .service-card a:hover {
    color: var(--blue-2);
}

#services .container .bottom .service-cards .service-card .logo-spin {
    z-index: 2;
    position: absolute;
    width: 30rem;
    left: -10rem;
}

#services .container .bottom .service-cards .service-card .logo-spin img {
    width: 100%;
}

#services .container .bottom .backdrop {
    position: absolute;
    height: 6rem;
    width: 30rem;
    background-color: var(--white-color);
    border-top-right-radius: 10rem;
    border-bottom-right-radius: 10rem;
    left: -10rem;
    top: 40%;
    z-index: 1;
}


#testimonies {
    background-image: url('./../../assets/images/Group-9.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#testimonies .container .top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 5rem;
}

#testimonies .container .top h1 {
    font-size: 4rem;
    color: var(--blue-2);
}

#testimonies .container .top p {
    color: var(--white-color);
    text-align: center;
    width: 50%;
}


#testimonies .container .bottom {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* #testimonies .container .bottom .testimony .quote-sign {
    border: solid red 2px;
} */

#testimonies .container .bottom .testimony .quote-sign img {
    width: 5rem;
}




#privacy {
    min-height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

#privacy .container {
    display: flex;
    justify-content: center;
    align-items: center;
}

#privacy .container article h1 {
    color: var(--grey-2);
    font-size: 3rem;
    text-align: center;
}

#privacy-bgVid {
    position: absolute;
    left: 0;
    width: 100vw;
    height: 40vh;
    object-fit: cover;
    z-index: -1;
}


/*============= MEDIA QUERIES (MEDIUM DEVICES) ================*/

@media screen and (max-width:1024px) {}



/*============= MEDIA QUERIES (SMALLER DEVICES) ================*/

@media screen and (max-width:600px) {

    #header-section .showcase .container {
        flex-direction: column;
    }

    #header-section .showcase .container .left {
        width: 100%;
        margin-top: 6rem;
    }

    #header-section .showcase .container .left h1 {
        font-size: 2.5rem;
    }

    #header-section .showcase .container .right {
        width: 100%;
    }

    #header-section .showcase .container .right img {
        width: 15rem;
    }

    #who-are-we {
        background-image: unset;
        min-height: 60vh;

    }

    #who-are-we .container {
        flex-direction: column;

    }

    #who-are-we .container .left {
        display: none;
    }

    #who-are-we .container .right {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        text-align: left;
    }

    #who-are-we .container .right h1 {
        font-size: 2.5rem;
    }


    #who-are-we .container .right p {
        text-align: left;
        width: 100%;
    }

    #mission {
        background-position: right;
        min-height: 45vh;

    }

    #mission .container article {
        width: 100%;
    }

    #mission .container article h1 {
        font-size: 2.5rem;

    }

    #mission .container article p {
        text-align: left;
        width: 100%;
    }



    #services .container .top h1 {
        font-size: 2.5rem;
    }

    #services .container .top p {
        width: 100%;
        text-align: left;
    }


    #services .container .bottom .service-cards {
        flex-direction: column;
    }

    #services .container .bottom .service-cards .service-card {
        width: 100%;
    }


    #services .container .bottom .service-cards .service-card .logo-spin {
        width: 30rem;
        left: -6rem;
    }

    #services .container .bottom .service-cards .service-card .logo-spin img {
        width: 100%;
    }

    #services .container .bottom .backdrop {
        top: 10%;

    }

    #testimonies {

        min-height: 100vh;

    }

    #testimonies .container .top {

        margin-bottom: 5rem;
    }

    #testimonies .container .top h1 {
        font-size: 2.5rem;
    }

    #testimonies .container .top p {
        text-align: left;
        width: 100%;
    }


    #testimonies .container .bottom {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #testimonies .container .bottom .testimony {
        flex-direction: column;
        width: 100%;
        /* border: solid red 2px; */
    }

    #testimonies .container .bottom .testimony p {
        width: 100%;
    }

    #testimonies .container .bottom .testimony .quote-sign img {
        width: 5rem;
    }


    /* .swiper-wrapper {
        margin: 0 2%;
    }

    .swiper-slide p {
        color: var(--white-color);
        margin: 1rem 0;
    } */



    #privacy {
        min-height: 40vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    #privacy .container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #privacy .container article h1 {
        font-size: 2.5rem;
    }

    #privacy-bgVid {
        width: 100vw;
        height: 40vh;
        object-fit: cover;

    }


}