#data_protection_showcase {
  position: relative;
  min-height: 100vh;
  background: url("../../assets/images/data.jpg") no-repeat center center/cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

#data_protection_showcase::after {
  content: "";
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.7);
}

.data_showcase_wrapper {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 2rem;
  margin-top: 7rem;
}

.data_showcase_wrapper .left {
  flex-basis: 100%;
}
.data_showcase_wrapper p {
  color: #fff;
  text-align: left;
  width: 100%;
}

.data_showcase_wrapper .head {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.data_showcase_wrapper .text {
  padding-left: 1rem;
}

.data_showcase_wrapper .text p:first-child {
  font-size: 3rem;
  line-height: 1.2;
  font-weight: 600;
  margin: 0;
  max-width: 600px;
}

.data_showcase_wrapper .text p:last-child {
  padding-top: 2rem;
  font-size: 1.5rem;
}

.data_showcase_wrapper .right {
  flex-basis: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.data_showcase_wrapper .right img {
  max-width: 300px;
}

/* ======= WHY:SECTION ======= */
#data_protection_why {
  min-height: 35rem;
}

.data_why_wrapper {
  margin: 5rem auto;
}

.data_why_wrapper .top {
  margin-bottom: 3rem;
}

.data_why_wrapper .top h2 {
  margin: 2rem 0;
}

.data_why_wrapper .top p {
  margin-bottom: 5rem;
  width: 80%;
  color: var(--grey-2);
}

.data_why_wrapper .bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 4rem;
}

.data_why_wrapper .why_card {
  position: relative;
  background: #ffffff;
  box-shadow: 6px 6px 4px rgba(5, 131, 210, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-height: 400px;
  padding: 3rem 2rem;
}

.data_why_wrapper .why_card::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 15px;
  width: 100%;
  background-color: var(--blue);
}

.data_why_wrapper .why_card .text p {
  width: 100%;
  color: var(--grey-2);
}

.data_why_wrapper .why_card .text p:first-child {
  font-size: 1.5rem;
  font-weight: 600;
  padding-top: 0.75rem;
}

.data_why_wrapper .bottom img {
  width: 70px;
  height: 70px;
}

/* ======= RANSOMEWARE SECTION ======= */
#data_ransomeware {
  position: relative;
  min-height: 25rem;
  background-color: var(--blue);
  padding: 5rem 0;
}

#data_ransomeware::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 10px;
  background: linear-gradient(90deg, #37acf5 0%, #09a9d4 100%);
}

.ransomeware_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ransomeware_wrapper .left,
.ransomeware_wrapper .right {
  width: 100%;
}

.ransomeware_wrapper .left img {
  width: 90%;
  border-radius: 50px;
}

.ransomeware_wrapper .right h2 {
  color: #fff;
  max-width: 500px;
}

.ransomeware_wrapper .right p {
  color: #fff;
  width: 100%;
}

/* ======= TRUST SECTION ======= */
#data_protection_trust {
  padding: 1rem;
}

.trust_wrapper {
  margin: 5rem auto;
}

.trust_wrapper .top {
  margin-bottom: 5rem;
}

.trust_wrapper .top h2 {
  color: var(--grey-2);
}

.trust_wrapper .top p {
  color: var(--grey-2);
  width: 90%;
}

.trust_wrapper .bottom {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  margin: auto;
}

.trust_wrapper .bottom img {
  max-width: 20%;
  filter: grayscale(100%);
  transition: 400ms ease;
}

.trust_wrapper .bottom img:hover {
  filter: grayscale(0%);
}

/* ======= MEDIA QUERY ======= */

@media screen and (max-width: 1000px) {
  .data_showcase_wrapper .text p:first-child {
    font-size: 2.5rem;
    line-height: 1.2;
    font-weight: 600;
    margin: 0;
    max-width: 600px;
  }
  .data_showcase_wrapper .right img {
    max-width: 250px;
  }
}

@media screen and (max-width: 900px) {
  .data_why_wrapper .why_card {
    padding: 3rem 1em;
  }

  .data_why_wrapper .why_card .text p:first-child {
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 800px) {
  .data_showcase_wrapper {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .data_showcase_wrapper .right img {
    max-width: 200px;
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 600px) {
  #data_protection_showcase {
    height: 100vh;
  }

  .data_showcase_wrapper .text p:first-child {
    font-size: 1.2rem;
  }

  .data_showcase_wrapper .text p:last-child {
    font-size: 1.3rem;
  }

  .data_why_wrapper .bottom {
    flex-direction: column;
    gap: 3rem;
  }

  .data_why_wrapper .top h2 {
    margin: 2rem 0;
    font-size: 2rem;
  }

  .data_why_wrapper .top p {
    width: 100%;
  }

  .ransomeware_wrapper {
    flex-direction: column;
    gap: 3rem;
  }

  .ransomeware_wrapper .left img {
    width: 100%;
    border-radius: 40px;
  }

  .ransomeware_wrapper .right h2 {
    font-size: 1.7rem;
  }

  .trust_wrapper {
    margin: 4rem auto;
  }

  .trust_wrapper .top h2 {
    font-size: 1.7rem;
  }

  .trust_wrapper .top p {
    width: 100%;
  }

  .trust_wrapper .bottom {
    gap: 1rem;
  }

  .trust_wrapper .bottom img {
    max-width: 25%;
  }
}
