/* showcase  */

.contact-us-showcase-1 {
    background: url('../../assets/images/contat.jpg') no-repeat center center/cover;
    height: 100vh;
    width: 100%;
}

.contact-us-showcase-2 {
    background: rgba(0, 0, 0, .6);
    height: 100vh;
    width: 100%;
}

.contact-us-showcase-txt {
    color: #fff;
    text-align: center;
    width: 82%;
    margin: auto;
}

@media screen and (max-width: 600px) {
    .contact-showcase {
        margin-top: 5rem;
}
}


/* showcase end */



/* CEO  */


.ceo-wrapper {
    background: linear-gradient(to right, var(--blue) 0%, var(--blue) 50%, #fff 33.33%, #fff 80%, var(--blue) 66.66%, var(--blue) 100%);
    width: 100%;
}

.ceo-case {
    padding: 4rem 0;
}

.ceo {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}

.ceo-left {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 45%;
    gap: 1rem;
    color: #fff;
}

.cl-a {
    font-size: 25px;
    font-weight: 700;
}

.cl-b {
    font-size: 16px;
    line-height: 1.7;
}


.ceo-right {
    width: 45%;
}

.ceo-right img {
    object-fit: cover;
    width: 100%;
    height: 400px;
}


@media screen and (max-width: 700px) {
    .ceo-case {
    padding: 4rem 0;
}

.ceo {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5rem;
}

.ceo-left {
    width: 100%;
    gap: 1rem;
}
.ceo-right {
    width: 100%;
}


.cl-a {
    font-size: 2rem;
    font-weight: 700;
}

.ceo-right img {
    height: 250px;
}

.ceo-wrapper {
    background: var(--blue);
}

}

/* CEO END */



/* perfect team  */


.pteam-wrapper {
    background: #fff;
    width: 100%;
}

.pteam-case {
    padding: 6rem 0;
}

.pteam {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6rem;
    width: 100%;
}

.pteam-top {
    color: var(--blue);
    font-size: 2rem;
    font-weight: 700;
}

.p-teams {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 3rem;
}

.p-team-1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 31%;
    box-shadow: var(--blue) 0px 10px 20px, var(--blue) 0px 6px 6px;
    padding-top: 2rem;
}

.p-team-1b {
    color: var(--blue);
    font-size: 1.5rem;
    font-weight: 600;
}
.p-team-1c {
    color: var(--grey-2);
    font-size: 16px;
}

.p-team-1d {
    color: #fff;
    font-size: 16px;
    background: var(--blue);
    padding: 1rem;
}

.p-team-1a-pic {
    border-radius: 50%;
    border: var(--blue) solid 7px;
    width: 170px;
    height: 170px;
}

.p-team-1a-pic img {
    border-radius: 50%;
    object-fit: cover;
    height: 100%;
    width: 100%;
}


@media screen and (max-width: 950px) {
    .p-teams {
   flex-direction: column;
    gap: 5rem;
}

.p-team-1 {
    gap: 1rem;
    width: 60%;
}

.pteam-case {
    padding: 3rem 0;
}

.pteam {
    gap: 3rem;
}

}


@media screen and (max-width: 600px) {
    .p-teams {
   flex-direction: column;
    gap: 5rem;
}

.p-team-1 {
    gap: 1rem;
    width: 80%;
}

}



/* perfect team end */


/* contact  */


.contact-wrapper {
    background: linear-gradient(to right, #fff 0%, #fff 50%, #fff 33.33%, #fff 80%, var(--blue) 66.66%, var(--blue) 100%);
    margin: 5rem 0;
    width: 100%;
}

.contact {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.contact-left {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;
    width: 45%;
}

.cl1 {
    font-size: 2rem;
    color: var(--blue);
    font-weight: 700;
}

.cl2 {
    font-size: 16px;
    line-height: 1.7;
    color: var(--grey-2);
}

.cl3 {
    width: 100%;
}
.cl4 {
    margin-top: 3rem;
}

.contact-form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    gap: 2rem;
    margin-top: 2rem;
}

input {
    outline: none;
    border-bottom: var(--grey-2) solid 1px;
    padding-bottom: 1rem;
    width: 100%;
    font-size: 17px;
    color: var(--grey-2);
}

.conf {
    width: 100%;
}

.contact-right {
    width: 45%;
}

.con-right-case {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.conr-bot {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 3em;
    padding: 4rem 3rem;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background: #fff;
}

.con-b1 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .8em;
}

.con-b1-a {
    width: 25px;
    height: 25px;
}
.con-b1-a img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

/* .conr-top {
    position: relative;
} */

.conr-top-rec {
    width: 70px;
    height: 70px;
    position: absolute;
    left: 50px;
    top: -32px;
}


.conr-top-rec img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.tm-btn {
    margin-top: 2rem;
}


@media screen and (max-width: 800px) {
    
    .contact {
    flex-direction: column;
    justify-content: center;
    gap: 8rem;
}

.contact-left {
    width: 100%;
    align-items: center;
}

.contact-right {
    width: 100%;
}

.contact-wrapper {
    background: linear-gradient(to bottom, #fff 0%, #fff 50%, #fff 33.33%, #fff 80%, var(--blue) 66.66%, var(--blue) 100%);
}

}


@media screen and (max-width: 600px) {
    .tm-btn {
    transform: translateX(50%);
}
}




/* contact end */