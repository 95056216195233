.privacy-wrapper {
    background: #fff;
}
.privacy-case {
    padding-top: 8rem;
    padding-bottom: 5rem;
}

.privacy {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;
}

.pri {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    gap: 1em;
}

.pri-a h1 {
    font-size: 2rem;
    /* color: var(--black-2); */
    color: var(--blue);
    text-align: center!;
}



.pri .pri-b {
    text-align: left;
    font-size: 16px;
    line-height: 1.7;
    color: var(--black-2);
}

.pri-c h2 {
    font-size: 1.5rem;
    color: var(--black-2);
}

.pri-d {
    text-align: left;
    font-size: 16px;
    line-height: 1.7;
    color: var(--black-2);
}

.pri-d ul li {
    list-style-type: disc;
    color: var(--black-2);
}

@media screen and (max-width: 600px) {
    .pri-a h1 {
    font-size: 2rem;
    text-align: center!;
}
}