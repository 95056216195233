@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
}

:root {
  --border-radius: 2rem;
  --card-border-radius: 1rem;
  --btn-padding: 0.6rem 2rem;
  --search-padding: 0.6rem 1rem;
  --card-padding: 1rem;
  --sticky-top-left: 5.4rem;
  --sticky-top-right: -18rem;

  --container-lg: 82%;
  --container-md: 90%;

  --black-color: #262626;
  --white-color: #fff;
  --grey-2: #808080;
  --black-2: #363636;
  --blue-2: #03b5e6;
  --blue: #0583d2;
  --light: #f8f9fa;
  --grey-color: rgb(161, 159, 159);
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Montserrat", sans-serif;
  line-height: 1.5;
  color: var(--black-color);
  background: var(--white-color);
  user-select: none;
  overflow-x: hidden;
}

.container {
  width: var(--container-lg);
  margin: 0 auto;
}

img {
  display: block;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5 {
  line-height: 1.2;
}

section h2 {
  text-align: center;
}

section p {
  font-size: 16px;
  text-align: center;
  width: 42%;
  margin: 0.6rem auto 2.5rem;
}

.showcase-txt-1 {
  font-size: 3rem;
  color: var(--blue);
  background: #fff;
  padding: 1rem 2rem !important;
  border-radius: 10px;
  font-weight: 800;
  text-align: center;
  font-weight: 700;
  position: absolute;
  top: 40%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.showcase-txt-2 {
  font-size: 20px;
  margin-top: 1rem;
  color: #fff;
  text-align: center;
  /* font-weight: 500; */
  position: absolute;
  top: 55%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 600px) {
  .showcase-txt-1 {
    font-size: 2rem;
  }

  .showcase-txt-2 {
    font-size: 17px;
    margin-top: 2rem;
    color: #fff;
    text-align: center;
    font-weight: 500;
    position: absolute;
    top: 55%;
    padding: 0;
    line-height: 1.7;
  }
}

@media screen and (max-width: 950px) {
  .showcase-txt-2 {
    padding: 0 1rem;
    line-height: 1.7;
  }
}

.ast2 {
  max-width: 85%;
}

.btn-main {
  display: inline-block;
  width: fit-content;
  padding: 0.75rem 1rem;
  border-top-left-radius: 5rem;
  border-bottom-right-radius: 5rem;
  background: var(--blue-2);
  color: var(--white-color);
  cursor: pointer;
  transition: all 400ms ease;
  min-width: 10rem;
  text-align: center;
  text-transform: capitalize;
}

.btn-main:hover {
  background: lighten(8%);
  transform: scale(0.94);
  box-shadow: 0 1rem 1.6rem rgba(#03b5e6, 0.15);
}

.btn-main-variant {
  display: inline-block;
  width: fit-content;
  padding: 0.75rem 1rem;

  background: var(--blue);
  color: var(--white-color);
  cursor: pointer;
  transition: all 400ms ease;

  min-width: 10rem;
  text-align: center;
  text-transform: uppercase;
}

.btn-main-variant:hover {
  background: lighten(var(--blue), 8%);
  box-shadow: 0 1rem 1.6rem rgba(var(--blue), 0.15);
}

.gradientBackground {
  background: linear-gradient(135deg, var(--blue-2), var(--blue));
  color: var(--white-color);
}

.line {
  height: 90px;
  width: 10px;
  background-color: var(--blue-2);
  margin-top: 10px;
}

@media screen and (max-width: 600px) {
  .line {
    height: 50px;
  }
}

.sub_head {
  font-size: 2.5rem;
  color: var(--blue-2);
  margin-bottom: 2rem;
}

/*============= MEDIA QUERIES (MEDIUM DEVICES) ================*/

@media screen and(max-width:1024px) {
  .container {
    width: var(--container-md);
  }

  section p {
    width: 55%;
  }
}

/*============= MEDIA QUERIES (SMALLER DEVICES) ================*/

@media screen and(max-width:600px) {
  section p {
    width: var(--container-md);
  }

  .btn,
  .btn2,
  .btn3,
  .btn4,
  .btn-primary,
  .btn-primary-variant {
    min-width: 6rem;
  }
}


p {
  font-size: 16px;
  line-height: 1.7;
}