.testimonySwiper {
  width: 90%;
  padding-bottom: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: solid red 2px; */
  /* display: flex !important; */
  /* flex-direction: column; */
  padding-right: 4rem;
  margin: 0 !important;
}

.testimonial {
  text-align: center;
  padding: 2rem;
  border-radius: 2rem;
  user-select: none;
  /* border: solid red 2px; */
}

.testimony {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2%;
  position: relative;
  width: 70%;
  /* border: solid red 2px; */
}

.swiper-wrapper {
  margin: 0 2%;
  /* border: solid red 2px; */
}

.swiper-slide p {
  color: var(--white-color);
  margin: 1rem 0;
  width: 80%;
  text-align: left;
  /* border: solid red 2px; */
}

.testifier {
  color: var(--blue-2);
  text-align: left;
  /* border: solid red 2px; */
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  color: var(--blue);
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  cursor: pointer;
  border: solid red 2px;
  /* display: flex; */
  /* align-items: center;
justify-content: center; */
}

.swiper-button-next::after,
.swiper-button-prev::after {
  display: none;
}

.testimonySwiper {
  position: unset;
  width: 100%;
}

.swiper-button-prev {
  background-image: url("./../../assets/images/arrow2.png");
  background-size: contain;
}

.swiper-button-next {
  background-image: url("./../../assets/images/arrow1.png");
  background-size: contain;
}

.testimonySwiper div.swiper-button-prev {
  left: 85%;
}

.testimonySwiper div.swiper-button-next {
  right: 1rem;
  /* margin-right: 1.5rem; */
}

.swiper-pagination-clickable .swiper-pagination-bullet {
  background: var(--blue);
}

@media screen and (max-width: 900px) {
  .testimony {
    width: 100%;
  }

  .testimonySwiper {
    width: 100%;
    padding-bottom: 4rem;
    padding-right: 0rem;
    padding-left: 1rem;
  }
  .testimonySwiper div.swiper-button-prev {
    left: 0;
  }

  .testimonySwiper div.swiper-button-next {
    right: 0;
  }
}
